import React from "react"

import * as styles from "./Promos.module.scss"
import SectionTitle from "./SectionTitle"
import LeaderList from "./LeaderList"
import Leader from "./Leader"

const determineLayoutType = (value) => {
    switch (value) {
        case 1:
            return "oneColumn"
        case 2:
            return "twoColumn"
        case 3:
            return "threeColumn"
        default:
            return ""
    }
}

const PromoRow = (props) => {
    return (
        <div className={styles.promoBar}>
            <a href={props.link ? props.link.link : null}>
                <h3>{props.title}</h3>
                <div className={styles.description}>
                    <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
                </div>
            </a>
        </div>
    )
}

const Promos = ({ promoItems }) => {
    const hasPromoRows = () =>
        promoItems && promoItems.promoBarItems && promoItems.promoBarItems.promoBarRows && promoItems.promoBarItems.promoBarRows.length > 0
    const hasPromoBlocks = () =>
        promoItems && promoItems.promoBlockItems && promoItems.promoBlockItems.promoBlocks && promoItems.promoBlockItems.promoBlocks.length > 0

    const buildLeaderFromPromoBlock = (block) => {
        const promo = { ...block }
        promo.highlightType = "custom_content"
        promo.link = block.link ? { url: block.link.link } : null

        return promo
    }

    return (
        <div className={styles.Promos}>
            {hasPromoRows() && (
                <>
                    <SectionTitle>Promos</SectionTitle>
                    <div className={styles.promoBars}>
                        {promoItems.promoBarItems.promoBarRows.map((row, i) => (
                            <PromoRow key={`promo-row-${i}`} {...row} />
                        ))}
                    </div>
                </>
            )}

            {hasPromoBlocks() && (
                <LeaderList layoutType={determineLayoutType(promoItems.promoBlockItems.promoBlocks.length)} styleType="promos">
                    {promoItems.promoBlockItems.promoBlocks.map((highlight, index) => (
                        <Leader key={`small-highlight-${index}`} {...buildLeaderFromPromoBlock(highlight)} />
                    ))}
                </LeaderList>
            )}
        </div>
    )
}

export default Promos

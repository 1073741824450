import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { detect } from "detect-browser"

// Styles
import * as styles from "./ObjectLeader.module.scss"

// Components
import PlaceholderImage from "./PlaceHolderImage"
import Image from "./Image"
import { imageSizes } from "../../helpers/imgix"

const BoxVariants = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, ease: "easeOut", when: "beforeChildren" },
    },
    hidden: { opacity: 0, y: 80 },
}

const textContentVariants = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.3, ease: "easeOut" } },
    hidden: { opacity: 0, x: 50 },
}

// Unset initial transition state for IE
const isIeBrowser = /(ie)/i.test(detect().name)
if (isIeBrowser) {
    BoxVariants.hidden.opacity = 1
    BoxVariants.hidden.y = 0
    textContentVariants.hidden.opacity = 1
    textContentVariants.hidden.x = 0
}

const ObjectLeader = (props) => {
    let highlightContent = {}
    if (props.highlightType && props.highlightType === "site_content") {
        if (props.highlightedContent) {
            highlightContent.title = props.highlightedContent.title
            highlightContent.description = props.highlightedContent.description
            highlightContent.image = props.highlightedContent.image.image
            highlightContent.link = { url: props.highlightedContent.link }
            highlightContent.contentType = props.highlightedContent.contentType
            highlightContent.venue = props.highlightedContent.venue
        } else {
            return <></>
        }
    } else {
        highlightContent.title = props.title
        highlightContent.description = props.description
        highlightContent.image = props.image
        highlightContent.link = props.link
    }

    const controls = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    const titleClass = "isNotLengthy"

    return (
        <motion.div
            className={`${styles.ObjectLeader}`}
            ref={ref}
            animate={controls}
            initial={typeof(window) !== 'object' ? 'visible' : 'hidden'}
            variants={BoxVariants}
            data-has-link={highlightContent.link ? true : null}
        >
            <a href={highlightContent.link && highlightContent.link.url}>
                {highlightContent.image && (
                    <div className={styles.image}>
                        <Image url={highlightContent.image.sourceUrl} alt={highlightContent.image.title} sizes={imageSizes.hero} />
                    </div>
                )}
                <motion.div className={styles.textContent} ref={ref} initial={typeof(window) !== 'object' ? 'visible' : 'hidden'} variants={textContentVariants}>
                    {/*{highlightContent.contentType && <span className={styles.superTitle}>{highlightContent.contentType.node.name}</span>}*/}

                    {highlightContent.title && <h3 className={styles[titleClass]} dangerouslySetInnerHTML={{ __html: highlightContent.title }}></h3>}

                    {highlightContent.description && (
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: highlightContent.description }}></div>
                    )}
                </motion.div>
            </a>
        </motion.div>
    )

}

export default ObjectLeader

import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getSectionLandingPagePreview as PREVIEW_QUERY } from "../preview-queries/sectionLandingPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Article from "../components/Shared/Article"
import LargeLandingHighlight from "../components/Shared/LargeLandingHighlight"
import Leader from "../components/Shared/Leader"
import LeaderList from "../components/Shared/LeaderList"
import SectionTitle from "../components/Shared/SectionTitle"
import HeroAreaLarge from "../components/Shared/HeroAreaLarge"
import SectionBlurb from "../components/Shared/SectionBlurb"
import LargeTextCTA from "../components/Shared/LargeTextCTA"
import { venueForPage } from "../helpers/venueForPage"
import VenueHireFooterCTA from "../components/Shared/VenueHireFooterCTA"
import Promos from "../components/Shared/Promos"
import SEOSettings from "../components/Shared/SEOSettings"
import ObjectContainer from "../components/Object/ObjectContainer"



const TemplateAdoptObject = (props) => {
    const page = getContent(props, "page")
    const venue = venueForPage({
        page: page,
        menus: props.data.menus,
    })

    const filters = {
        price: props.data.price,
    }


    // console.log(props)


    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaLarge title={page.title} introduction={page.page_introduction.introduction} image={page.image.image} />
            <Main>

                <SectionTitle>{venue.navigation.name}</SectionTitle>

                

                <Article>
                    <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
                </Article>

                <ObjectContainer {...props} filters={filters} />

                {/*{props.pageContext.hasNextPage && <LoadMore onClick={() => navigate(`/adopt-an-object=${props.pageContext.pageNumber + 1}`)} />}*/}

            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query AdoptObjectPageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }
        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            ...LargeHighlights
            ...SmallHighlights
            ...LargePageCTA
            ...FooterCTASettings
            ...PromoItems
        }
        objects: allWpObject {
            results: nodes {
                ...BasicObjectsContent
            }
        }
        price: allWpObjectPrice {
            nodes {
              name
              slug
              termTaxonomyId
            }
        }

    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateAdoptObject)

import React, { createContext, useState } from "react"

const defaultValues = {
    objectFilters: {},
}

const ObjectContext = createContext(defaultValues)

const ObjectContextProvider = ({ children }) => {
    const [objectFilters, setObjectFilters] = useState({})
    

    return <ObjectContext.Provider value={{ objectFilters, setObjectFilters }}>{children}</ObjectContext.Provider>
}

export { ObjectContext, ObjectContextProvider }

import React, { useState, useCallback, useMemo, useEffect, useContext } from "react"
import { Select } from "grommet"

import DateInput from "../Shared/DateInput"
// import { DateInput } from 'grommet-controls' // DateInput in Grommet Controls doesn't work with non-US date formats

import * as styles from "./ObjectFilters.module.scss"
import { decode } from "../../helpers/stringHelpers"
import { useQueryString } from "../../utils/hooks/useQueryString"

import { ObjectContext } from "../../context/ObjectContext"

const ObjectFilters = (props) => {
    const [refresh, setRefresh] = useState(false)
    const [priceValue, setPriceValue] = useState({ title: 'Placeholder', id: null });
    const [sortValue, setSortValue] = useState({})

    // Object Context
    const { allFilters, setAllFilters } = useContext(ObjectContext)

    // if (typeof window === "undefined") {
    //     var window = { eventFilters: {} }
    // }

    // console.log(setAllFilters)
    // return

    // const { value: queryStringValues, updateQueryString, initialQueryRun } = useQueryString()

    const filters = useMemo(() => {
        return Object.keys(props.filters)
            .filter((filter) => {
                return !(props.hideVenueFilter && filter === "venue")
            })
            .map((filterType) => {
                // console.log(filterType)
                /*
        pass the given __typename in to return the filter title. 
        todo: figure out why the FilterFields query returns null's for the following...

        labels: contentType {
            node {
                label
            }
        }
        */
                const filterName = "price"

                const filterTitle = props.filters["price"].nodes[0].name
                // console.log("FILTERS: ", props.filters["price"].nodes)
                const filterOptions = props.filters["price"].nodes
                .map((option) => ({
                    id: option.termTaxonomyId,
                    title: decode(option.name),
                    slug: option.slug,
                }))
                .sort((a, b) => a.title - b.title);

                // filterOptions.unshift({
                //     id: null,
                //     title: 'Placeholder',
                //     slug: null
                // });
                return {
                    id: filterType,
                    name: filterTitle,
                    options: filterOptions,
                }
            })
    })

    // Get all filters options, names, ids
    const priceObject = useMemo(() => {
        return filters.find((obj) => {
            return obj.id === "price"
        })
    }, [])

    const sortObject = {
        id: "sort",
        name: "Sort",
        options: [
            { id: "a-to-z", title: "A to Z" },
            { id: "z-to-a", title: "Z to A" },
        ],
    }

    // Set Query Filter Value and Update Select component values
    const setFilterValue = useCallback(
        (id, valueID) => {
            switch (id) {
                case "price":
                    setPriceValue(valueID)
                    // console.log("valueID:", valueID)
                    break

                default:
                    break
            }
            let filter = {}
            filter[id] = valueID

            const savedFilters = allFilters
            let filters = { ...savedFilters, ...filter }

            const queryFilters = filters

            // updateQueryString({ ...queryFilters })
            props.onChange(filters)
        },
        [allFilters]
    )

    const resetFilters = useCallback(() => {
        // window.eventFilters = {}
        // setAllFilters({})

        // Remove filter values
        setPriceValue({})

        // Update Query String
        // let filters = {}
        // updateQueryString({ ...filters })

        props.onChange(filters)

        setRefresh(!refresh)
    }, [])

    // Get Values from query String on page load
    // Update Select Filter Values
    // Set Filters
    // useEffect(() => {
    //     if (!queryStringValues) {
    //         return
    //     }

    //     const { price } = queryStringValues

    //     let filters

    //     if (price) {
    //         filters = { ...filters, price }

    //         const filterOption = priceObject.options.find((obj) => {
    //             console.log("filterOption", obj)
    //             return obj.id === parseInt(price)
    //         })
    //         setPriceValue({ title: filterOption.title, id: filterOption.id })
    //     }
    //     console.log("FILTERS:", filters)
    //     // setAllFilters({ ...filters })

    //     props.onChange(filters)
    // }, [queryStringValues])

    return (
        <div>
            <div className={styles.ObjectResetFilters}>
                <button onClick={() => resetFilters()}>Reset Filters</button>
            </div>

            <div className={styles.ObjectFilters} key={`refresh-${refresh}`}>
                {/* Price Filter */}
                {/* {console.log(priceValue)} */}
                {priceObject?.options && (
                    <Select
                        key={`filter-${priceObject?.id}`}
                        data-filter-id={priceObject?.id}
                        options={priceObject?.options.map(option => ({ 
                            ...option, 
                            title: `£${option.title}` 
                          }))}
                        labelKey={`title`}
                        valueKey={`id`}
                        placeholder={`By cost`}
                        value={priceValue ? priceValue.slug : "select a price"}
                        onChange={({
                            target: {
                                dataset: { filterId },
                            },
                            value,
                        }) => {

                            if (value.id !== null) {
                                // console.log("selected Value: ", value, "price Value: ", priceValue)
                                setFilterValue(filterId, value.slug)
                            }
                            // console.log("selected Value: ", value, "price Value: ", priceValue)
                            // setFilterValue(filterId, value.slug)
                        }}
                    />
                )}
                {/* <div>
                    {sortObject?.options && (
                        <Select
                            key={`filter-${sortObject?.id}`}
                            data-filter-id={sortObject?.id}
                            options={sortObject?.options}
                            labelKey={`title`}
                            valueKey={`id`}
                            placeholder={sortObject?.name}
                            value={allFilters?.sort || ""}
                            onChange={({
                                target: {
                                    dataset: { filterId },
                                },
                                value,
                            }) => setFilterValue(filterId, value.id)}
                        />
                    )}
                </div> */}
            </div>
        </div>
    )
}

export default ObjectFilters

import React, { useEffect, useMemo, useCallback, useState, useContext } from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

// Components
import LeaderList from "../../components/Shared/LeaderList"
import HeroAreaSmall from "../../components/Shared/HeroAreaSmall"
import Main from "../../components/Shared/Main"
import ObjectFilters from "../../components/Object/ObjectFilters"
import ObjectLeader from "../../components/Shared/ObjectLeader"
import LoadMore from "../Shared/LoadMore"
import Loading from "../Shared/Loading"

import { ObjectContext } from "../../context/ObjectContext"

import * as styles from "./ObjectContainer.module.scss"

export const query = gql`
query ObjectQuery($first: Int!, $after: String, $notIn: [ID!], $price: String) {
    objects(
          first: $first
after: $after
where: {
notIn: $notIn
price: $price
orderby: { field: Suggested_Donation, order: ASC }
}

    ) {
        results: nodes {
            id
            databaseId
            title
            link
            excerpt
            contentType {
                node {
                    name
                }
            }
            image: hero_fields {
                image {
                    sourceUrl
                    title
                }
            }
            adoption {
                adoption {
                    adoptedBy
                    giftTapLink {
                        target
                        title
                        url
                    }
                    suggestedDonation {
                        name
                    }
                }
            }
        }
        pageInfo {
            hasNextPage
        }
    }
}
`

const PER_PAGE = 9
const PAGINATION_OFFSET = 0 // initial page load gets 9 but we then render addition rows of 9

// Window Object
let store = typeof window === "undefined" ? {} : window

const ObjectContainer = (props) => {
    // ObjectContext

    // const { allFilters, setAllFilters } = useContext(ObjectContext)
    const [params, setParams] = useState({ first: PER_PAGE, after: null })

    let { data, loading, fetchMore } = useQuery(query, {
        variables: params,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        errorPolicy: "all",
    })

    // console.log('PARAMS', params)
    // console.log('DATA', data)

    const updateFilteredResults = useCallback(
        (filters) => {
            const updatedParams = { ...params, ...filters }
            setParams(updatedParams)
        },
        [params]
    )

    // const allObjects = useMemo(() => {
    //     let objects

    //     if (data) {
    //         objects = data?.objects ? data?.objects?.results : []
    //     } else {
    //         objects = props.data?.objects.results
    //     }

    //     // console.log("OBJECTS", objects)
    //     return [...objects]
    // })

    const [sortedObjects, setSortedObjects] = useState([]);

    useEffect(() => {

        let objects;

        if (data) {
            objects = data?.objects ? data?.objects?.results : []
        } else {
            objects = props.data?.objects.results
        }

        const sorted = [...objects].sort((a, b) => {
        const donationA = a.adoption.adoption.suggestedDonation ? parseInt(a.adoption.adoption.suggestedDonation.name) : 0;
        const donationB = b.adoption.adoption.suggestedDonation ? parseInt(b.adoption.adoption.suggestedDonation.name) : 0;

        return donationA - donationB;
        });

        setSortedObjects(sorted);
    }, [data, props.data]);

    // console.log(sortedObjects)

    return (
        <>
            <Main>
                <ObjectFilters onChange={updateFilteredResults} filters={props.filters} hideVenueFilter={props.venueId ? true : false} />

                {sortedObjects.length === 0 && <p>No Objects found</p>}

                {!loading ? (
                    <>
                        <LeaderList layoutType="threeColumn">
                            {sortedObjects.map((object) => {
                                const modifiedLink = object.link.replace("https://cms.derbymuseums.org/", "https://derbymuseums.org/");

                                const desc = `Suggested donation to adopt: \n£${object.adoption.adoption.suggestedDonation.name}`
                                return <ObjectLeader key={object.id} {...object} image={object.image.image} link={{url: modifiedLink }} description={desc} />
                            })}
                        </LeaderList>
                    </>
                ) : (
                    <div>
                        <Loading loading={true} />
                    </div>
                )}

                {data && (
                    <LoadMore
                        onClick={() => {
                            fetchMore({
                                variables: {
                                    first: params.first + PAGINATION_OFFSET,
                                    after: null,
                                    notIn: sortedObjects.map((e) => e.databaseId),
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) {
                                        return prev
                                    }

                                    let results = [...prev.objects.results, ...fetchMoreResult.objects.results]
                                    return {
                                        ...prev,
                                        ...{ objects: { results, pageInfo: fetchMoreResult.objects.pageInfo } },
                                    }
                                },
                            })
                        }}
                        disabled={data && data.objects && !data.objects.pageInfo.hasNextPage}
                        loading={loading}
                    />
                )}
            </Main>
        </>
    )
}

export default ObjectContainer

import gql from "graphql-tag"

export const getSectionLandingPagePreview = gql`
    fragment FooterCTASettings on Page {
        footer_cta_options {
            showPeople
            title
            description
            people {
                ... on Person {
                    id
                    title
                    person_fields {
                        ctaDescription
                        email: emailAddress
                        role
                        phone: telephoneNumber
                        image {
                            sourceUrl
                            title
                        }
                    }
                }
            }
        }
    }

    fragment PromoItems on Page {
        promoItems {
            promoBarItems {
                promoBarRows {
                    title
                    description
                    link {
                        ... on Page {
                            id
                            link
                        }
                        ... on News {
                            id
                            link
                        }
                        ... on Event {
                            id
                            link
                        }
                    }
                }
            }
            promoBlockItems {
                promoBlocks {
                    title
                    description
                    image {
                        sourceUrl
                        title
                    }
                    link {
                        ... on Event {
                            id
                            link
                        }
                        ... on News {
                            id
                            link
                        }
                        ... on Page {
                            id
                            link
                        }
                    }
                }
            }
        }
    }

    query getSectionLandingPagePreview($id: ID!, $revision_id: Int!) {
        page(id: $id, idType: DATABASE_ID) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        link
                        content
                        contentType {
                            node {
                                name
                            }
                        }
                        venue {
                            venue {
                                ... on Venue {
                                    venueId: databaseId
                                    slug
                                    venue_fields {
                                        hasCustomNavigationMenu
                                        venueNavigationMenuId
                                    }
                                }
                            }
                        }
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        page_introduction {
                            introduction
                        }
                        sub_title {
                            subTitle
                        }
                        large_highlights {
                            sections: largeHighlightsSections {
                                description: sectionDescription
                                title: sectionTitle
                                highlights {
                                    title
                                    description
                                    highlightType
                                    image {
                                        title
                                        sourceUrl
                                    }
                                    link {
                                        title
                                        url
                                    }
                                    highlightedContent {
                                        ... on Page {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on Event {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on Collection {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on Venue {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on News {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        small_highlights {
                            sections: smallHighlightsSections {
                                description: sectionDescription
                                title: sectionTitle
                                highlights {
                                    title
                                    description
                                    highlightType
                                    image {
                                        title
                                        sourceUrl
                                    }
                                    link {
                                        title
                                        url
                                    }
                                    highlightedContent {
                                        ... on Page {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on Event {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on Collection {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on Venue {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                        ... on News {
                                            id
                                            title
                                            link
                                            image: hero_fields {
                                                image {
                                                    sourceUrl
                                                    title
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        large_page_cta {
                            largePageCta {
                                buttonText
                                link {
                                    url
                                    title
                                }
                                text
                                title
                            }
                        }
                        ...FooterCTASettings
                        ...PromoItems
                    }
                }
            }
        }
    }
`
